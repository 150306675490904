import { Inject, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class InitializerProvider {

  constructor(
    @Inject(BfcTranslationService) private bfcTranslateService: BfcTranslationService,
    @Inject(BfcGoogleAnalyticsService) private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    @Inject(BfcConfigurationService) private bfcConfigurationService: BfcConfigurationService,
    @Inject(MsalService) private msalService: MsalService,
  ) {}

  public initialize(): void {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );

    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    if (globalNavElements.length === 1) {
      let elements = [
        {
          key: "jobs",
          hide: true,
        },
        {
          key: "contact",
          hide: true,
        },
        {
          key: "login",
          hide: true,
        },
        {
          key: "logout",
          titleTranslatable: this.bfcTranslateService.translate("NAVIGATION.LOGOUT"),
          eventId: "logoutEvent",
          icon: "bfc-icon-arrow-right",
        },
      ];
      const serviceItemsModify = JSON.stringify(elements);
      globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);
      globalNavElements[0].addEventListener("globalNavigationEvent", (event: any) => {
        if (event.detail.eventId === "logoutEvent") {
          this.msalService.logout();
        }
      });
    } else {
      throw (new Error("too many or no global navigation element found"));
    }
  }
}
