import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from "@azure/msal-angular";
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from "./msal-factories";
import { BfcConfigurationModule, BfcConfigurationService } from "@bfl/components/configuration";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BfcConfigurationModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [BfcConfigurationService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [BfcConfigurationService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [BfcConfigurationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
})
export class MsalApplicationModule { }

