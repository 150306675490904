import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { BfcConfigurationService } from "@bfl/components/configuration";

export function MSALInstanceFactory(bfcConfigurationService: BfcConfigurationService): IPublicClientApplication {
  return new PublicClientApplication(bfcConfigurationService.configuration.msalConfig);
}

export function MSALInterceptorConfigFactory(
  bfcConfigurationService: BfcConfigurationService,
): MsalInterceptorConfiguration {
  // workaround, because new Map() in msal configuration is recognized only as typeof object
  const config = structuredClone(bfcConfigurationService.configuration.msalAngularInterceptorConfig);
  config.protectedResourceMap = new Map<string, Array<string>>(config.protectedResourceMap);
  return config;
}

export function MSALGuardConfigFactory(bfcConfigurationService: BfcConfigurationService): MsalGuardConfiguration {
  return bfcConfigurationService.configuration.msalAngularGuardConfig;
}
