export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "PeV Tool",
  "LOGIN_PAGE": {
    "VOTE_OVER": {
      "TITLE": "Die Frist für die Stimmabgabe ist abgelaufen.",
      "TEXT": "Die Wahlergebnisse publizieren wir Mitte November.",
    },
    "LOGIN_FORM": {
      "TITLE": "Loggen Sie sich ein, um die Personalkommission zu wählen.",
      "LOGIN": "Anmelden",
      "ERROR_MESSAGE": "Die von Ihnen eingegebenen Zugangsdaten sind nicht korrekt.",
      "NO_CODE": "Kein Code bekommen?",
      "MAIL": {
        "ADDRESS": "SPEKOWahlausschuss@bkw.ch",
        "SUBJECT": "Kein Code erhalten",
      },
      "PLACEHOLDER": {
        "USERNAME": "Benutzername",
        "PASSWORD": "Passwort",
      },
    },
  },
  "THANK_YOU_PAGE": {
    "TITLE": "Vielen Dank für Ihre Stimmabgabe.",
    "TEXT": "Die Wahlergebnisse publizieren wir Mitte November.",
  },
  "NO_ACCESS_PAGE": {
    "TITLE": "Sie sind nicht wahlberechtigt.",
    "TEXT_BOLD": "Sie können leider nicht and der Wahl teilnehmen, ",
    "TEXT_REGULAR": "weil Sie nicht zu den wahlberechtigten Mitarbeiter zählen.",
  },
  "ALREADY_VOTED_PAGE": {
    "TITLE": "Sie haben bereits gewählt und können sich nicht nochmals einloggen.",
    "TEXT": "Die Wahlergebnisse publizieren wir Mitte November.",
  },
  "VOTE_PAGE": {
    "TITLE": "Wählen Sie die neue PersonalVertretung (PeV)",
    "STEP_1": {
      "TITLE": "Zu Ihrer Information",
      "PARAGRAPH_1": "Um eine regionale und bereichsübergreifende Personalvertretung zu bilden, haben wir die Kandidatinnen und Kandidaten in drei Wahlkreise aufgeteilt. Pro Wahlkreis besetzen wir 4 bis 7 Sitze. Zudem ist jeder Wahlkreis nochmals in Bereiche unterteilt, um eine möglichst breite Vertretung sicherzustellen.",
      "PARAGRAPH_2":  "Die beiden Kandidatinnen und Kandidaten mit den meisten Stimmen pro Bereich sind gewählt. Die restlichen ein bis zwei Sitze pro Wahlkreis werden mit den Personen besetzt, die am nächstmeisten Stimmen erzielt haben. ",
      "PARAGRAPH_LIST": {
        "TITLE": "So läuft die Wahl ab",
        "CONTENT": "<li>Wahlberechtigt und wählbar sind alle hauptamtlich und auf unbestimmte Dauer angestellten Mitarbeitenden der BKW Energie AG mit Ausnahme der Konzernleitung, der Mitarbeitenden der Level 1 und 2, der Mitarbeitenden des Human Resources Management sowie der Mitglieder des Wahlausschusses.  </li>" +
            "<li>Sie dürfen alle Kandidierenden wählen, nicht nur jene in Ihrem Wahlkreis. </li>" +
            "<li>Pro Wahlkreis können Sie so viele Stimmen abgeben, wie der Wahlkreis Sitze in der Personalvertretung hat.</li>" +
            "<li>Verteilen Sie wenn möglich all Ihre Stimmen, dies ist aber kein Muss.</li>" +
            "<li>Sie können jede Kandidatin und jeden Kandidaten nur einmal wählen.</li>" +
            "<li>Kandidierende dürfen auch sich selber wählen.</li>" +
            "<li>Sind Ihre Stimmen einmal verteilt und abgegeben, können Sie die Wahl nicht mehr ändern.</li>",
      },
      "PARAGRAPH_3": "Bitte wählen Sie aus den folgenden Kandidatinnen und Kandidaten die neue Personalvertretung.",
    },
    "STEP_2": {
      "HEADING": "Wahlkreis 1: <b>(5 Sitze)</b>",
      "TITLE": "Wahlkreis Hauptsitz",
      "INFORMATION": "Sie können in diesem Wahlkreis maximal 5 Personen durch Ankreuzen wählen",
    },
    "STEP_3": {
      "HEADING": "Wahlkreis 2: <b>(4 Sitze)</b>",
      "TITLE": "Wahlkreis Produktion",
      "INFORMATION": "Sie können in diesem Wahlkreis maximal 4 Personen durch Ankreuzen wählen",
    },
    "STEP_4": {
      "HEADING": "Wahlkreis 3: <b>(7 Sitze)</b>",
      "TITLE": "Wahlkreis E und N",
      "INFORMATION": "Sie können in diesem Wahlkreis maximal 7 Personen durch Ankreuzen wählen",
    },
    "VOTE_CANCEL": {
      "TITLE": "Sie haben die Wahl abgebrochen.",
      "TEXT": "Sie können bis einschliesslich {{date}} Ihre Stimmen abgeben.",
    },
    "MAX_EXCEEDED": "Im Wahlkreis {{voteRound}} können maximal {{maxNumber}} Stimmen vergeben werden.",
    "VOTES_REMAINING": "Sie können noch <b>{{number}} von {{maxNumber}} Stimmen</b> in diesem Wahlkreis vergeben",
  },
  "BUTTON": {
    "NEXT": "Weiter",
    "VOTE": "Jetzt abstimmen",
    "DECLINE_VOTE": "Wahl abbrechen",
    "CLOSE": "Schliessen",
    "BACK": "Zurück",
  },
  "CANDIDATE_PAGE": {
    "PROFILE_LINK": "Profil ansehen",
  },
  "CANDIDATE_DIALOG": {
    "BIRTHDATE": "Jahrgang:",
    "ENTRY_DATE_BKW": "Eintrittsdatum BKW:",
    "MOTIVATION": "Motivation:",
    "PEV_STATE": "PEV Status:",
  },
  "SHARED": {
    "PEV_STATE_NEW": "neu",
    "PEV_STATE_OLD": "bisher",
    "ERROR_MESSAGE": "Ups, da ist wohl etwas schiefgelaufen… Bitte melde <a href=\"mailto:SPEKOWahlausschuss@bkw.ch?subject=Fehlerbeschreibung Wahltool\" target=\"_blank\">uns</a> den Fehler, damit wir ihn möglichst schnell beheben können.",
  },
  "NAVIGATION": {
    "LOGOUT": "Logout",
  },
};
