import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class PicturesService {

  constructor(private bfcConfigurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  public getPicture(candidateId: string): Observable<any> {
    // return this.httpClient.get(this.bfcConfigurationService.configuration.pekoApiUrl + `services/candidate/picture/${candidateId}`).pipe(
    //     map((response: Response) => response)
    // )

    return this.httpClient.get(`https://api-pev-wahlen-t.bkw.ch/api/services/candidate/picture/${candidateId}`).pipe(
      map((response: Response) => response),
    );
  }
}
