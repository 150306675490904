import { Component, OnInit } from "@angular/core";
import { filter, Subject, takeUntil } from "rxjs";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { Title } from "@angular/platform-browser";
import { BfcTranslationService } from "@bfl/components/translation";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private browserTitle: Title,
    private bfcTranslationService: BfcTranslationService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {
    this.browserTitle.setTitle(this.bfcTranslationService.translate("SERVICE_NAME"));
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.unsubscribe),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
}
