import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BFC_CONFIGURATIONS, BfcConfigurationModule } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BFC_TRANSLATIONS, BfcTranslationModule } from "@bfl/components/translation";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { MsalRedirectComponent } from "@azure/msal-angular";

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    BfcTranslationModule,
    BfcConfigurationModule,
    BfcNotificationModule,
    BfcGoogleAnalyticsModule,
    BfcSinglePageLayoutModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
